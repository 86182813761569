import React from "react";
import { Link } from "react-router-dom";
import logo from "../shared/img/logo/ez-code_H.png";
export const HomePage = () => {
    return (
        <div className="body">
            <img src={logo} alt="ezcode_logo" />
            {/* <h1 className="ezcode">EZcode</h1> */}
            <br />
            <h1 className="data_science_inc">Data Science Inc</h1>

            <h1 className="email">
                <Link to="mailto:info@ezcode.ca">Email: info@ezcode.ca</Link>
            </h1>

            <h1 className="phone">
                <Link to="tel:+12507979060">Phone: (250) 797-9060</Link>
            </h1>
        </div>
    );
};
