import React from 'react';
import { HomePage } from './HomePage';
import { Route, Routes } from 'react-router-dom';
import { AboutPage } from './AboutPage';

function MainPage() {
  return (
    <>
      <Routes >
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </>
  );
}

export default MainPage;
